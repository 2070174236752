<template>
  <div
    v-if="!gcInvite_inviteInfo.invitee_terms_more_ui"
    class="mb-n2 term-box ql-editor"
    v-html="gcInvite_inviteInfo.invitee_terms_html" />
</template>

<script>
export default {
  name: 'TermBox'
}
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

.term-box
  border: 1px solid map-get($grey, 'base')
  border-radius: 4px
  max-height: 16rem
  overflow: auto
</style>